import * as React from 'react'
import { useEffect, useState } from 'react'
import OfferCardListItem from './offer-card-list-item'
import { Offer } from '../../contracts/offer'

export interface OfferCardListProps {
  offers: Pick<Offer, 'id' | 'title' | 'offeredBy'>[]
}

interface GroupedOffers {
  letter: string
  offers: OfferCardListProps['offers']
}

export default ({ offers }: OfferCardListProps) => {
  const [groupedOffers, setGroupedOffers] = useState<GroupedOffers[]>()
  
  useEffect(() => {
    const groups = offers.reduce<GroupedOffers[]>((prev, curr) => {
      if (!prev.find(p => p.letter === curr.offeredBy.name[0].toLowerCase())) {
        prev.push({
          letter: curr.offeredBy.name[0].toLowerCase(),
          offers: []
        })
      }
      prev.find(p => p.letter === curr.offeredBy.name[0].toLowerCase())!.offers.push(curr)
      return prev
    }, [])
    setGroupedOffers(groups)
  }, [offers])

  if (!groupedOffers) {
    return <></>
  }
  
  return (
    <div className='md:grid-cols-3 lg:grid-cols-4 auto-rows-[220px]'>
      {groupedOffers.map(({ letter, offers }) =>
        <div key={letter}>
          <h2 className="text-xl border-b mb-8">{letter.toUpperCase()}</h2>

          <div className="max-w-xl mx-auto">
            {offers.map(offer =>
              <OfferCardListItem key={offer.id} offer={offer} className='mx-2 mb-4' />
            )}  
          </div>
        </div>
      )}
    </div>
  )
}