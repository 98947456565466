import { Link } from 'gatsby'
import React, { ChangeEvent, KeyboardEventHandler, useEffect, useRef, useState } from 'react'
import Loading from '../../components/loading/loading'
import { useAuthStore } from '../../stores/auth-store'
import { useMembershipStore } from '../../stores/membership-store'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import OfferCardList from '../../components/offer-card-list/offer-card-list'
import Login from '../../components/login/login'
import { Offer } from '../../contracts/offer'
import useDebounce from '../../components/use-debounce/use-debounce'

export const Head = () => {
  return (
    <title>My Offers - Disco Deals</title>
  )
}

const acclamations = [
  'Woo-hoo',
  'Yay',
  'Yippee',
  'Hooray',
  'Hurrah',
  'Huzzah',
  'Bravo',
  'Hot Dog',
  'Wahoo',
  'Whee',
  'Whoopee',
  'Yahoo',
  'Gee Whiz',
  'Zowie',
  'Wow'
]

const randomAcclamation = () => acclamations[Math.floor(Math.random() * acclamations.length)]

export default () => {
  const user = useAuthStore(state => state.user)
  const userLoaded = useAuthStore(state => state.isLoaded)
  const membershipStore = useMembershipStore()
  const [isLoading, setIsLoading] = useState(true)
  const [acclamation] = useState(randomAcclamation())
  const [signedOut, setSignedOut] = useState(false)
  const [searchInput, setSearchInput] = useState<string>('')
  const [searchResults, setSearchResults] = useState<Offer[] | undefined>()

  const inputRef = useRef(null)
  const handleClearResults = () => {
    clearSearch()
  }

  const handleSearchChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Escape') {
      clearSearch()
    }
  }

  const clearSearch = () => {
    (inputRef.current as any).value = ''
    setSearchResults(undefined)
    setSearchInput('')
  }

  useDebounce(() => {
    const searchTerm = searchInput.toLowerCase().trim()
    const matches = membershipStore.membershipOffers?.filter(o =>
      o.availableThrough.name.toLowerCase().includes(searchTerm)
      || o.title.toLowerCase().includes(searchTerm)
    )
    setSearchResults(matches)
  }, [searchInput], 800)
  
  useEffect(() => {
    setSignedOut(false)
    if (!userLoaded || membershipStore.membership) {
      return
    }
    if (!user) {
      setIsLoading(false)
      setSignedOut(true)
      return
    }
  }, [userLoaded, user])

  if (membershipStore.membershipOffers && isLoading) {
    setIsLoading(false)
  }

  return (
    <main className='pt-4 container'>
      <div>
        <Link to='/memberships/choose' className='button button-outline button-flat py-3 block float-right'>
          Update my memberships
          <ArrowRightIcon className='inline-block w-4 ml-2' />
        </Link>
        <h1 className='page-title'>My Offers</h1>
      </div>

      {isLoading && <div className="mt-4 flex justify-center">
        <Loading />
      </div>}

      {signedOut &&
        <div className='max-w-md mx-auto'>
          <p className='mb-2 text-center'>
            <span className='font-semibold text-lg text-gray-800'>Oops</span> it looks like you're not signed in.
          </p>
          <p className='mb-8 text-gray-600 text-center'>
            To access your discounts, please sign in below.
          </p>
          <Login />
        </div>
      }

      {membershipStore.membershipOffers && membershipStore.membershipOffers.length > 0 && 
        <div>
          <p className='mb-4 text-center'>
            <span className='text-4xl block mb-2'>🎉 {acclamation}! 🎉</span>
            You have access to over <span className='font-semibold text-xl'>{membershipStore.membershipOffers.length}</span> discounts!
          </p>

          <div className='mb-4'>
            <input
              type='text'
              placeholder='Search...'
              onKeyDown={handleKeyDown}
              ref={inputRef}
              className='bg-white border border-gray-200 rounded-full placeholder-gray-400 text-sm transition-all w-full py-3 px-6'
              onChange={handleSearchChanged}
            ></input>
          </div>
          
          <OfferCardList offers={searchResults ?? membershipStore.membershipOffers} />
        </div>
      }

      {membershipStore.membershipOffers && membershipStore.membershipOffers.length === 0 &&
        <div className='text-center'>
          <p className='mt-2 font-medium text-gray-900'>No offers found.</p>
          <p className='mt-1 text-gray-500'>Please <Link to='/memberships/choose' className='text-primary-600 hover:underline'>update your memberships</Link> to view discounts available to you.</p>
        </div>
      }

      {searchInput.length > 0 && searchResults?.length === 0 && 
        <div className='text-center'>
          <p className='mt-2 font-medium text-gray-900'>No search results found.</p>
        </div>
      }

    </main>
  )
}