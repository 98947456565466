import React, { useState } from 'react'
import SocialLoginButton from '../../components/social-login-button/social-login-button'
import { startSignInWithEmailLink, signInWithGoogle, signInWithFacebook } from '../../services/auth'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { classNames } from '../../utility/class-names'
import { useAuthStore } from '../../stores/auth-store'

export interface LoginProps {
  onLoggedIn?: () => void
}

export default ({ onLoggedIn }: LoginProps) => {
  const [isSendingLink, setIsSendingLink] = useState(false)
  const authStore = useAuthStore()
  
  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()
    e.stopPropagation()
    setIsSendingLink(true)
    const email = (e.target as any)[0].value
    await startSignInWithEmailLink(email)
  }

  const handleSigninWithGoogle = async () => {
    await signInWithGoogle(authStore)
    onLoggedIn?.()
  }

  const handleSigninWithFacebook = async () => {
    await signInWithFacebook(authStore)
    onLoggedIn?.()
  }
  
  return (
    <div>
      <div className="flex flex-col space-y-2">
        <SocialLoginButton
          className="text-gray-700 hover:text-gray-800 hover:bg-gray-100 dark:bg-gray-800 dark:text-gray-100 dark:hover:bg-gray-700 dark:border-gray-500"
          label="Continue with Google"
          onClick={handleSigninWithGoogle}
          image={
            <StaticImage
              src={'../../../static/images/social-login/google.png'}
              alt='Google'
              className="mr-3 w-5"
            />
          }
        />

        {/* <SocialLoginButton
          className="bg-[#1877F2] text-gray-100 hover:text-white hover:bg-blue-500 dark:hover:bg-blue-500 dark:text-gray-100 dark:border-gray-500"
          label="Continue with Facebook"
          onClick={handleSigninWithFacebook}
          image={
            <StaticImage
              src={'../../../static/images/social-login/facebook.png'}
              alt='Facebook'
              className="mr-3 w-5"
            />
          }
        /> */}

        {/* <div>
          <SocialLoginButton />
        </div>

        <div>
          <SocialLoginButton />
        </div> */}
      </div>

      <div className="mt-6">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300 dark:border-gray-600" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white dark:bg-transparent text-gray-500 dark:text-gray-200">Or</span>
          </div>
        </div>

        <form className="space-y-6 mt-4" onSubmit={handleFormSubmit}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Email me a sign in link
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="Email address"
                required
                className={
                  classNames(
                    'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm',
                    'dark:bg-gray-800 dark:text-white'
                  )
                }
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}