import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { useState } from 'react'
import { Offer } from '../../contracts/offer'
import { classNames } from '../../utility/class-names'

export interface OfferCardProps {
  key?: string
  className?: string
  offer: Pick<Offer, 'id' | 'title' | 'offeredBy'>
}

export default ({ offer, className, ...rest }: OfferCardProps) => {
  const imageSrc = `/images/companies/${offer.offeredBy.id}.jpg`
  const image = getImage(offer.offeredBy.image)

  const [image404, setImage404] = useState(false)
  
  return (
    <Link
      to={`/offers/${offer.id.toLowerCase()}`}
      className={classNames(
        className,
        'card card-link text-center py-8 flex-row',
        'grid grid-cols-3 space-x-4'
      )}
      {...rest}
    >
      <div
        className='h-20'
      >
        {!image &&
          <img
            src={imageSrc}
            alt={offer.offeredBy.name}
            className={
              classNames(
                'object-contain w-full h-full',
                image404 ? 'hidden' : ''
              )
            }
            onError={() => setImage404(true)}
          />
        }
      </div>
      <p className='col-span-2 text-md font-regular text-center'>{offer.title}</p>
    </Link>
  )
}