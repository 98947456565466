import React from 'react'
import { classNames } from '../../utility/class-names'
import { StaticImage } from 'gatsby-plugin-image'

export interface SocialLoginButtonProps {
  label: string
  image: React.ReactNode
  className?: string
  onClick?: () => void
}

export default ({ label, onClick, className, image }: SocialLoginButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={
        classNames(
          'py-2 px-4 w-full text-left border border-gray-300 rounded-md shadow-sm text-sm font-medium',
          className
        )
      }
    >
      {image}
      <span className="font-semibold">{label}</span>
    </button>
  )
}